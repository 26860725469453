<template>
  <div class="container">
    <h2>Matching Columns</h2>
    <p>You have matched {{ numberOfMatchedColumns }} columns from {{ numberOfTotalColumns }}</p>
    <table class="table table-bordered">

      <thead>
      <tr>
        <th>#</th>
        <th>System Column</th>
        <th>Mandatory</th>
        <th>Excel Column</th>
        <th>Matched</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(row,i) in result">

        <td><span>{{ i + 1 }}</span></td>

        <td>
          <span v-if="!row.extra">{{ row.systemColumn }}</span>
          <input v-else type="text" class="form-control" v-model="row.systemColumn">
        </td>

        <td>
          <span :style="[row.excelColumn && row.systemColumn ?
                        {'color':'#6dc030'} :{'color':'red'}]"
                v-if="row.required">YES</span>
          <span v-else>NO</span>
        </td>

        <td>
          <el-select class="select-default"
                     size="large"
                     clearable
                     filterable
                     @change="rebuildExcelHeaders"
                     v-model="row.excelColumn">
            <el-option v-for="option in localExcelColumns"
                       class="select-default"
                       :value="option.name"
                       :label="option.name"
                       :key="option.name"
                       :disabled="option.disabled">
            </el-option>
          </el-select>
        </td>

        <td>
          <i v-if="row.excelColumn && row.systemColumn" style="color: #6dc030" class="fa fa-check"></i>
          <i v-else class="fa fa-close" style="color:red"></i>
        </td>

        <td>
          <l-button v-if="((i + 1 === result.length) && (result.length < numberOfTotalColumns))"
                    type="success"
                    @click="addExtraRow()"
                    size="sm">
             <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </l-button>&nbsp;

          <l-button v-if="row.extra"
                    type="danger"
                    @click="removeExtraRow(i)"
                    size="sm">
             <span class="btn-label">
                    <i class="fa fa-minus"></i>
                </span>
          </l-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {Switch as LSwitch} from '@/components/index'
import {Select, Option} from 'element-ui';

export default {

  name: 'columns-chooser',

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch
  },

  props: {
    excelColumns: {
      type: Array,
      required: true
    },
    systemColumns: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      result: null,
      localExcelColumns: null
    };
  },

  computed: {

    numberOfMatchedColumns() {
      return this.result.filter(row => {
        return row.systemColumn && row.excelColumn
      }).length;
    },

    numberOfTotalColumns() {
      return this.localExcelColumns.length;
    }

  },

  created() {
    this.prepareLocalExcelColumns(this.excelColumns);
    this.prepareResultWithRequiredColumns(this.systemColumns);
  },

  watch: {
    excelColumns: function (value) {
      this.prepareLocalExcelColumns(this.excelColumns);
      this.prepareResultWithRequiredColumns(this.systemColumns);
    },

    systemColumns: function (value) {
      this.prepareLocalExcelColumns(this.excelColumns);
      this.prepareResultWithRequiredColumns(this.systemColumns);
    },

  },

  methods: {

    prepareLocalExcelColumns(excelColumns) {
      this.localExcelColumns = excelColumns.map(column => {
        return {
          name: column,
          disabled: false
        }
      });
    },

    prepareResultWithRequiredColumns(requiredColumns) {
      this.result = requiredColumns.map(column => {
        return {
          systemColumn: column.label,
          required: column.required,
          extra: false,
          excelColumn: this.getDefaultMatchingValue(column.label),
        }
      });
    },

    getDefaultMatchingValue(requiredColumn) {
      let excelColumn = this.localExcelColumns.find(row => {
        return ((this.prepareColumnNameForMatching(row.name) === this.prepareColumnNameForMatching(requiredColumn)) && row.disabled === false);
      });

      if (excelColumn) {
        excelColumn.disabled = true;
        return excelColumn.name;
      } else return null;
    },

    prepareColumnNameForMatching(value) {
      let new_string = value.replace(/[^a-zA-Z0-9]/g, '');
      return new_string.trim().toLowerCase();
    },

    addExtraRow() {
      this.result.push({
        systemColumn: "",
        required: true,
        excelColumn: null,
        extra: true,
      })
    },

    removeExtraRow(index) {
      this.result.splice(index, 1);
      this.rebuildExcelHeaders();
    },

    rebuildExcelHeaders() {

      let selectedExcelColumns = this.result.filter(row => {
        return row.excelColumn
      }).map(row => {
        return row.excelColumn
      });

      if (selectedExcelColumns.length > 0) {
        this.localExcelColumns.map(row => {
          row.disabled = !!selectedExcelColumns.includes(row.name);
        })
      } else {
        this.localExcelColumns.map(row => {
          row.disabled = false;
        })
      }
    },

    validate() {

      let unSelectedColumns = this.result.filter(row => {
        return !row.excelColumn || !row.systemColumn
      });

      let numberOfRequiredColumns = unSelectedColumns.filter(row => {
        return row.required
      });

      if (numberOfRequiredColumns.length > 0) {
        this.$notify({
          message: "Please match all the required fields in the table and complete the process",
          timeout: 6000,
          type: 'danger'
        });
        return false;
      }

      return true;
    }

  },
}
</script>

<style scoped lang="scss">

.table > thead > tr > th, .el-table__body > thead > tr > th {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-weight: 600;
}

.table tbody td:first-child, .table thead th:first-child {
  padding: 10px;
}

tr {
  text-align: center;
}

.card .table tbody td:last-child, .card .table thead th:last-child {
  display: revert;
}
</style>
