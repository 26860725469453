import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let settingsRoutes = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/list',
  children: [
    {
      path: 'list',
      name: 'settings List',
      component:()=>import(/* webpackChunkName: "settings" */ '@/pages/Dashboard/Settings/SettingList'),
      meta: {
        permissionCode: 'settings/index',
      }
    },
    {
      path: 'create',
      name: 'Add Setting',
      component:()=>import(/* webpackChunkName: "settings" */ '@/pages/Dashboard/Settings/SettingForm'),
      meta: {
        permissionCode: 'settings/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Setting',
      component:()=>import(/* webpackChunkName: "settings" */ '@/pages/Dashboard/Settings/SettingForm'),
      meta: {
        permissionCode: 'settings/update',
      }
    },
    {
      path: 'tracking-jobs-list',
      name: 'Tracking Logs List',
      component:()=>import(/* webpackChunkName: "settings" */ '@/pages/Dashboard/Settings/TrackingLogsList'),
      meta: {
        permissionCode: 'tracking-jobs/get-logs',
      }
    },
    {
      path: 'portal-images',
      name: 'Portals Images',
      component:()=>import(/* webpackChunkName: "settings" */ '@/pages/Dashboard/protalImages/PortalImagesForm'),
      meta: {
        permissionCode: 'portal-images/update',
      }
    }
  ]
}

export default settingsRoutes;
