import axios from 'axios';

export default {
  namespaced: true,

  state: {
    welcomeImage: null,
    sideBarImage: null,
    mediaInfo: null,
  },

  getters: {
    welcomeImage(state) {
      return state.welcomeImage;
    },

    sideBarImage(state) {
      return state.sideBarImage;
    },

    mediaInfo(state) {
      return state.mediaInfo;
    },
  },

  mutations: {
    SET_WELCOME_IMAGE(state, welcomeImage) {
      state.welcomeImage = welcomeImage;
    },

    SET_SIDE_BAR_IMAGE(state, sideBarImage) {
      state.sideBarImage = sideBarImage;
    },

    SET_MEDIA_INFO(state, mediaInfo) {
      state.mediaInfo = mediaInfo;
    },

  },

  actions: {

    async index({commit, state}) {
      try {
        let res = await axios.get("global/index");
        commit('SET_WELCOME_IMAGE', res.data.images.welcome_page_image);
        commit('SET_SIDE_BAR_IMAGE', res.data.images.side_bar_image);
        commit('SET_MEDIA_INFO', res.data.mediaInfo);
      } catch (e) {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      }

    }

  }
}
