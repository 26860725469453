import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let companiesRoutes = {
  path: '/companies',
  component: DashboardLayout,
  redirect: '/companies/list',
  children: [
    {
      path: 'list',
      name: 'Companies List',
      component: () => import(/* webpackChunkName: "companies" */ '@/pages/Dashboard/companies/CompaniesList'),
      meta: {
        permissionCode: 'companies/index',
      }
    },
    {
      path: 'create',
      name: 'Add Company',
      component: () => import(/* webpackChunkName: "companies" */ '@/pages/Dashboard/companies/CompanyForm'),
      meta: {
        permissionCode: 'companies/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Company',
      component: () => import(/* webpackChunkName: "companies" */ '@/pages/Dashboard/companies/CompanyForm'),
      meta: {
        permissionCode: 'companies/update',
      }
    },
  ]
}

export default companiesRoutes;
