<template>
  <ul class="nav nav-mobile-menu">
    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-single-02"></i>
      </template>
      <a @click.prevent="$router.push('/update-profile')" href="javascript:void(0)"  class="dropdown-item">
        <i class="nc-icon nc-single-02"></i>My Profile
      </a>
      <a @click.prevent="$router.push('/change-my-password')" href="javascript:void(0)"  class="dropdown-item">
        <i class="nc-icon nc-key-25"></i>Change Password
      </a>
      <a @click.prevent="logout()" href="javascript:void(0)"  class="dropdown-item text-danger">
        <i class="nc-icon nc-button-power"></i> Log Out
      </a>
    </drop-down>
  </ul>
</template>
<script>
import DropDown from 'src/components/Dropdown.vue'
import {mapActions} from "vuex";

export default {
  components: {
    DropDown
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),

    logout() {
      this.logoutAction().then(() => {
        this.$router.push("/login");
      });
    },
  }
}
</script>
<style>
</style>
