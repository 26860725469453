<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <notifications></notifications>
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
        <div class="container">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="#">
              <img class="nav-logo" src="static/pim-tv-logo.png" alt="Pim">
            </a>
          </div>
        </div>
      </nav>
      <div class="full-page" :data-color="backgroundColor" :class="pageClass">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple | black" -->
        <div class="content">
          <div class="container">
            <slot></slot>
          </div>
        </div>

        <div class="full-page-background"
             :style="{ backgroundImage: 'url(' +getBackground()+ ')' }"></div>
        </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
  import {mapGetters} from "vuex";

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    props: {
      pageClass: {
        type: String,
        default: 'login-page'
      },
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },

    computed: {
      ...mapGetters({
        welcomeImage: "global/welcomeImage",
      })
    },

    data () {
      return {
        showMenu: false
      }
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      getBackground() {
        return process.env.VUE_APP_SERVER_IMAGE_URL + this.welcomeImage;
      }
    },

    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style scoped>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  .navbar-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  .wrapper-full-page .navbar-toggler,
  .wrapper-full-page .navbar-collapse .navbar-nav{
    margin-right: 30px;
  }

  .navbar {
    padding: 0px !important;
  }

  .navbar-collapse .navbar-nav .nav-link {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .navbar-brand {
    width: 140px;
    height: 140px;
    padding: 10px !important;
    margin: 0 !important;
    margin-left: 10px !important;
    opacity: .95 !important;
    background-color: #fff;
  }

  .navbar-brand:hover {
    background-color: #fff !important;
  }

  .nav-logo {
    height: 100%;
  }

</style>
