import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let usersRoutes = {
  path: '/users',
  component: DashboardLayout,
  redirect: '/users/list',
  children: [
    {
      path: 'list',
      name: 'Users List',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/Dashboard/users/UserList'),
      meta: {
        permissionCode: 'users/index',
      }
    },
    {
      path: 'create',
      name: 'Add User',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/Dashboard/users/UserForm'),
      meta: {
        permissionCode: 'users/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit User',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/Dashboard/users/UserForm'),
      meta: {
        permissionCode: 'users/update',
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change User Password',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/Dashboard/users/ChangeUserPassword'),
      meta: {
        permissionCode: 'users/change-password',
      }
    },
  ]
}

export default usersRoutes;
