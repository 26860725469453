<template>
  <div>

    <el-upload
      ref="upload"
      action="#"
      :http-request="doUpload"
      :show-file-list="false"
      accept="image/*"
    >
      <el-button size="small" type="primary">Click to upload</el-button>
    </el-upload>

    <ul v-if="fileList.length > 0" class="el-upload-list el-upload-list--picture">
      <li v-for="item in fileList" tabindex="0" class="el-upload-list__item is-success">
        <img :src="getUrl(item.path)" alt="" class="el-upload-list__item-thumbnail">

        <a @click="handlePictureCardPreview(item.path)" class="el-upload-list__item-name">
          <i class="el-icon-document"></i>Preview The Image</a>

        <label class="el-upload-list__item-status-label"><i
          class="el-icon-upload-success el-icon-check"></i></label>

        <i @click="handleRemove(item.path)" class="el-icon-close"></i><i class="el-icon-close-tip">press delete to
        remove</i>
      </li>
    </ul>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';

export default {

  name: "ImageUploader",

  props: {
    numberOfFiles: {
      type: Number,
      required: true
    },
    inputList: {
      type: Array,
      required: true,
      default: []
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button
  },

  created() {
    this.prepareComponent(this.inputList);
  },

  watch: {
    inputList: function () {
      this.prepareComponent(this.inputList);
    }
  },

  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
      headers: {
        'Authorization': "Bearer " + this.$store.getters["auth/token"]
      },
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileList: [],
      numberOfUploadedItems: 0,
    };
  },

  methods: {
    doUpload(params) {
      if (this.fileList.length < this.numberOfFiles) {
        let bodyFormData = new FormData();
        bodyFormData.append('photo', params.file);
        this.axios.post(this.uploadUrl,
          bodyFormData,
          {
            headers: {"Content-Type": "multipart/form-data"},
          }).then(response => {

          this.fileList.push({
            url: process.env.VUE_APP_SERVER_IMAGE_URL + response.data.data.url,
            path: response.data.data.url
          });

          this.numberOfUploadedItems++;

          this.$emit('change', this.fileList);

        }).catch(err => {
          console.log(err.msg)
        })
      }
    },

    handleRemove(path) {
      this.fileList = this.fileList.filter(x => {
        return x.path !== path;
      });

      this.$emit('change', this.fileList);
    },

    handlePictureCardPreview(path) {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + path;
      this.dialogVisible = true;
    },

    getUrl(path) {
      return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },

    prepareComponent(inputList) {
      this.fileList = [...inputList];
    }

  }

}
</script>
