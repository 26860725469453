<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar :background-image="getImage()">

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('companies')"
                      :link="{name: 'Companies', icon: 'nc-icon nc-bank'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('companies/index')"
                        :link="{name: 'Companies', path: '/companies/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('projects')"
                      :link="{name: 'Projects', icon: 'nc-icon nc-notes'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('projects/index')"
                        :link="{name: 'Projects', path: '/projects/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('members')"
                      :link="{name: 'Members', icon: 'nc-icon nc-circle-09'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('members/index')"
                        :link="{name: 'Members', path: '/members/list'}"></sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/checkAccess']('access_management')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('permissions/index')"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('settings')"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('settings/index')"
                        :link="{name: 'Settings', path: '/settings/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('tracking-jobs/get-logs')"
                        :link="{name: 'Tracking Logs', path: '/settings/tracking-jobs-list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('portal-images/update')"
                        :link="{name: 'Portals Images', path: '/settings/portal-images'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('mediaInfo/index')"
                        :link="{name: 'Media Info', path: '/mediaInfo/list'}"></sidebar-item>
        </sidebar-item>


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'
import {mapGetters} from "vuex";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },

  computed: {
    ...mapGetters({
      sideBarImage: "global/sideBarImage"
    })
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    getImage() {
      return process.env.VUE_APP_SERVER_IMAGE_URL + this.sideBarImage;
    }
  }
}

</script>
