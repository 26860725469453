import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import NotFound from '@/pages/GeneralViews/NotFoundPage.vue'
import Overview from '@/pages/Dashboard/Dashboard/Overview.vue'
import Login from '@/pages/Dashboard/Pages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import membersRoutes from "@/routes/membersRoutes";
import UpdatePassword from "@/pages/Dashboard/profile/UpdatePassword";
import UpdateProfile from "@/pages/Dashboard/profile/UpdateProfile";
import ForgetPassword from "@/pages/Dashboard/Pages/ForgetPassword";
import ResetPassword from "@/pages/Dashboard/Pages/ResetPassword";
import projectsRoutes from "@/routes/projectsRoutes";
import companiesRoutes from "@/routes/companiesRoutes";
import settingsRoutes from "@/routes/settingsRoutes";
import mediaInfoRoutes from "@/routes/mediaInfoRoutes";

let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};

let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  usersRoutes,
  membersRoutes,
  projectsRoutes,
  companiesRoutes,
  settingsRoutes,
  mediaInfoRoutes,
  dashboardRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
