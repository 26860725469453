import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let membersRoutes = {
  path: '/members',
  component: DashboardLayout,
  redirect: '/members/list',
  children: [
    {
      path: 'list',
      name: 'Members List',
      component: () => import(/* webpackChunkName: "members" */ '@/pages/Dashboard/members/MemberList'),
      meta: {
        permissionCode: 'members/index',
      }
    },
    {
      path: 'create',
      name: 'Add Member',
      component: () => import(/* webpackChunkName: "members" */ '@/pages/Dashboard/members/MemberForm'),
      meta: {
        permissionCode: 'members/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Member',
      component: () => import(/* webpackChunkName: "members" */ '@/pages/Dashboard/members/MemberForm'),
      meta: {
        permissionCode: 'members/update',
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change Member Password',
      component: () => import(/* webpackChunkName: "members" */ '@/pages/Dashboard/members/ChangeMemberPassword'),
      meta: {
        permissionCode: 'members/change-password',
      }
    },
  ]
}

export default membersRoutes;
