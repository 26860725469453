import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let projectsRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/list',
  children: [
    {
      path: 'list',
      name: 'Project List',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ProjectList'),
      meta: {
        permissionCode: 'projects/index',
      }
    },
    {
      path: 'create',
      name: 'Add Project',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ProjectForm'),
      meta: {
        permissionCode: 'projects/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Project',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ProjectForm'),
      meta: {
        permissionCode: 'projects/update',
      }
    },
    {
      path: 'upload-source-file/:id',
      name: 'Upload Source File',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/SourceData/UploadSourceData'),
      meta: {
        permissionCode: 'projects-source-data/upload-data',
      }
    },
    {
      path: 'source-file-list/:id',
      name: 'Project Source Data',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/SourceData/ProjectSourceDataList'),
      meta: {
        permissionCode: 'projects-source-data/get-data',
      }
    },
    {
      path: 'upload-shipping-file/:id',
      name: 'Upload Shipping File',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ShippingData/UploadShippingData'),
      meta: {
        permissionCode: 'projects-shipping-data/upload-data',
      },
    },
    {
      path: 'shipping-file-list/:id',
      name: 'Project Shipping Data',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ShippingData/ProjectShippingDataList'),
      meta: {
        permissionCode: 'projects-shipping-data/get-data',
      }
    },
    {
      path: 'add-shipping-data/:projectId',
      name: 'Shipping Data Form',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ShippingData/AddDataForm'),
      meta: {
        permissionCode: 'projects-shipping-data/add-row',
      }
    },
    {
      path: 'update-shipping-data/:id',
      name: 'Shipping Data Form',
      component: () => import(/* webpackChunkName: "projects" */ '@/pages/Dashboard/Projects/ShippingData/UpdateDataForm'),
      meta: {
        permissionCode: 'projects-shipping-data/get-row',
      }
    },
  ]
}

export default projectsRoutes;
