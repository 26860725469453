<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Reset Password</h3>
                </div>
                <div>
                  <ValidationProvider
                    vid="password"
                    rules="required"
                    name="The Password"
                    v-slot="{ passed, failed,errors }"
                  >
                    <fg-input type="password"
                              :error="failed ? errors[0]: null"
                              :hasSuccess="passed"
                              label="Password"
                              v-model="formData.password">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    rules="required|confirmed:password"
                    name="The Confirmed Password"
                    v-slot="{ passed, failed ,errors}">
                    <fg-input type="password"
                              :error="failed ? errors[0]: null"
                              :hasSuccess="passed"
                              label="Confirmed Password"
                              name="confirm"
                              v-model="formData.confirmed_password">
                    </fg-input>

                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button type="submit" :disabled="submitting" class="btn btn-fill btn-info btn-round btn-wd ">Reset
                  </button>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {FadeRenderTransition} from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import {extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout
  },
  data() {
    return {
      submitting: false,
      formData: {
        password: "",
        confirmed_password: "",
      },
    };
  },

  methods: {
    submit() {
      this.submitting = true;
      this.axios.post("auth/reset-password/" + this.$route.params['token'], this.formData).then((res) => {
        this.$notify({
          message: "Password Updated Successfully.",
          timeout: 10000,
          type: 'success'
        });
        this.$router.push("/login");
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 5000,
          type: 'danger'
        })
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
