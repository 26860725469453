import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

let mediaInfoRoutes = {
  path: '/mediaInfo',
  component: DashboardLayout,
  redirect: '/mediaInfo/list',
  children: [
    {
      path: 'list',
      name: 'Media Info List',
      component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/Dashboard/mediaInfo/MediaInfoList'),
      meta: {
        permissionCode: 'mediaInfo/index',
      }
    },
    {
      path: 'create',
      name: 'Add Media Info Item',
      component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/Dashboard/mediaInfo/MediaInfoForm'),
      meta: {
        permissionCode: 'mediaInfo/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Media Info Item',
      component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/Dashboard/mediaInfo/MediaInfoForm'),
      meta: {
        permissionCode: 'mediaInfo/update',
      }
    }
  ]
}

export default mediaInfoRoutes;
